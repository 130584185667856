import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styles from '../styles/main.module.scss';

const Header = ({ siteTitle }) => {
  return (
    <header>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.headerTitleWrapper}>
            <Link to="/">
              <h2 className={styles.headerTitle}>{siteTitle}</h2>
            </Link>
          </div>
          <div className={styles.topNav}>
            <Link to="/projects/" activeClassName={styles.topNavActive}>
              Projects
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
