import React from 'react';
import styles from '../styles/main.module.scss';

const Footer = ({ siteTitle }) => {
  return (
    <footer>
      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div>
            <span>
              © {new Date().getFullYear()} {siteTitle || ''}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
