import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';
import styles from '../styles/main.module.scss';

const Layout = ({ children, noFooter = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className={styles.layout}>
      <Header siteTitle={data.site.siteMetadata.title} topNav={`Projects`} />
      <main className={styles.layoutContent}>
        {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        <>{children}</>
      </main>
      {!noFooter && (
        <div className={styles.layoutContentPush}>
          <Footer siteTitle={data.site.siteMetadata.title} />
        </div>
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
